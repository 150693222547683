@import 'trendolizer-ui/build/index.css';

/* Global styles 
 ================================================================ */
:root {
  --Image-fallback: url(./img/fallback.svg);
  --UserBadge-fallback: url(./img/user-placeholder.svg);

  --layout-max-width: 64rem;
  --notification-size: 24rem;
  --element-line-cap: calc((var(--base-line) * 2) - var(--element-size) + 2px);
}

.layout-container {
  display: block;
  max-width: var(--layout-max-width);
  margin: 0 auto;
}
html,
body {
  height: 100%;
  width: 100%;
  overflow: auto;
}

.PasswordField {
  position: relative;
  .TextInput input {
    padding-right: var(--element-size);
  }
  .Button {
    position: absolute;
    right: 0;
    top: 1.5rem;
    --Button-bdrs: var(--bdrs);
  }
}

.ResultList-item {
  padding-top: 1rem;
  padding-left: var(--layout-gap-horizontal);
  padding-right: var(--layout-gap-horizontal);
}

/* User info block styles
 ================================================================ */
.UserInfo {
  position: relative;

  &-content {
    display: grid;
    grid-gap: 0 0.5rem;
    grid-template-columns: 3rem 1fr;
    grid-template-rows: auto;
    cursor: pointer;
  }
  &-image {
    grid-row-end: span 2;
    justify-self: center;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    user-select: none;
  }
  &-name,
  &-position {
    user-select: none;
    line-height: inherit;
    margin-top: calc(var(--base-line-cap) * -0.5);
  }
  &-menu {
    --appearVert-start: -1rem;
    position: absolute;
    top: calc(100% + 0.75rem);
    left: 0;
    z-index: 10;
    animation: appearVert var(--transition-duration);
    animation-fill-mode: forwards;
    padding: 0;
    width: 8.5rem;
    &-button {
      --element-size: 2rem;
      width: 100%;
      text-align: left;
    }
  }
}

/* History block styles
 ================================================================ */
.HistoryBlock {
  min-width: 30rem;
  background-color: var(--color-elevated);
  border-radius: var(--element-bdrs);
  box-shadow: var(--shadow-depth-3);
  &-item {
    display: flex;
    width: 100%;
    align-items: center;
    background: none;
    border: none;
    padding: 0.75rem var(--layout-gap-horizontal);
    color: var(--color-text-secondary);
    cursor: pointer;
    text-align: left;
    transition: background var(--transition-duration);
    &:hover {
      background-color: var(--color-hover-tint);
    }
  }
  &-param {
    display: block;
    margin-right: 0.5rem;
  }
  &-resultnum {
    margin-left: auto;
    flex: 0 0 auto;
  }
}

/* Query form styles
 ================================================================ */
.QueryForm {
  &-container {
    --Select-bdrs: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 auto;
    max-width: var(--layout-max-width);
    .Select-control {
      border-radius: 0;
    }
  }
  &-textinput {
    flex: 1 1 auto !important;
    margin-right: -1px;
    input {
      border-radius: var(--element-bdrs) 0 0 var(--element-bdrs);
      padding-right: var(--element-size);
    }
  }
  &-providers {
    flex: 0 0 29% !important;
    margin-right: -1px;
    .Select-value:not(:first-child) {
      display: none;
    }
    .Select-option.is-selected {
      background-color: var(--color-secondary-ltr);
    }

    .Select-option[aria-label^='All'] {
      font-weight: bold;
    }
  }
  &-sorting {
    flex: 0 0 19% !important;
    margin-right: -1px;
  }
  &-submit {
    border-radius: 0 var(--element-bdrs) var(--element-bdrs) 0;
    flex: 0 0 auto;
  }
  &-actions {
    margin-left: 0.5rem;
  }
  &-history {
    &-button {
      margin-left: calc(var(--element-size) * -1) !important;
      margin-top: auto;
      margin-bottom: auto;
      z-index: 1;
    }
    &-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      width: 65%;
      z-index: 10;
    }
  }
  .state-highlighted {
    position: relative;
    z-index: 1;
    box-shadow: 0 0 6px 3px var(--color-secondary-lt),
      inset 0 0 12px 0 var(--color-secondary-lt);
  }
}

/* Column creation form styles
 ================================================================ */
.CreateColumn {
  background-color: var(--color-elevated);
  box-shadow: var(--shadow-depth-4);
  overflow: hidden;
  padding: 0;
  border-radius: 50%;
  width: var(--element-size);
  height: var(--element-size);
  max-height: 9rem;
  transition-property: padding, width, height, border-radius;
  transition-duration: var(--td);
  &-form {
    opacity: 0;
    animation: fadeIn var(--td) ease-in var(--td);
    animation-fill-mode: forwards;
  }
  &-title {
    display: block;
    font-size: 1.125rem;
    line-height: 1.125;
    margin-bottom: 0.5rem;
    cursor: default;
    user-select: none;
  }
  &-descr {
    display: block;
    clear: both;
    margin-top: 0.75rem;
    line-height: 1.125;
    color: var(--color-text-secondary);
  }
  &-input {
    margin-bottom: 0.5rem;
    float: left;
    margin-right: 8px;
    width: 14rem;
  }
  &-trigger {
    border-radius: var(--element-bdrs);
    opacity: 0;
    animation: fadeIn var(--td) ease-in var(--td);
    animation-fill-mode: forwards;
  }
  &.state-open {
    padding: 0.5rem;
    width: 19rem;
    height: auto;
    border-radius: var(--element-bdrs);
  }
}

/* Login form styles
 ================================================================ */
.LoginModal {
  &-row {
    margin: 1rem 0;
  }
}

/* No results styles
 ================================================================ */
.NoResults {
  display: flex;
  align-items: flex-start;
  height: 100%;
  padding-top: 4rem;
  &-title {
    line-height: 1.5;
  }
  &-steps {
    margin-top: 1rem;
  }
  &-illustration {
    flex: 0 0 20rem;
    width: 20rem;
    margin-right: 1rem;
    user-select: none;
  }
}

/* Result styles
 ================================================================ */
.Result {
  --Result-image-size: 200px;
  display: grid;
  grid-gap: 0.75rem;
  grid-template-columns: var(--Result-image-size) 1fr 1fr 1fr 1fr;
  grid-template-rows: auto auto calc((var(--Result-image-size) * 9) / 16) auto;
  &-header {
    grid-column: span 5;
  }
  &-title {
    margin: 0;
    padding: 0;
    display: inline;
    font-size: var(--font-size-h4);
    line-height: 1.25;
  }
  &-url {
    grid-column: span 5;
    width: 100%;
    display: block;
    font-size: 0.875em;
    line-height: 1.25;
    color: inherit;
  }
  &-description {
    grid-column: span 4;
    margin-top: -0.4em;
    padding: 0;
    overflow: hidden;
  }
  &-media {
    order: 0;
  }
  &-stat {
    &-max {
      color: #d87f21;
    }
    .Icon {
      vertical-align: -1px;
      margin-right: 0.4em;
    }
  }
  &-selector {
    margin-right: 0.5rem;
    display: inline-flex;
    vertical-align: -3px;
  }
}

/* Main application styles
 ================================================================ */
.Application {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  min-width: var(--layout-max-width);
  overflow: hidden;
  &-header {
    display: grid;
    position: relative;
    grid-template-columns: 180px 1fr 180px;
    grid-gap: 0 var(--layout-gap-horizontal);
    background-color: var(--color-elevated);
    box-shadow: var(--shadow-depth-2);
    padding: var(--layout-gap-vertical) var(--layout-gap-horizontal);
    &-logo {
      overflow: hidden;
      margin: 0;
      padding: 0;
      user-select: none;
      img {
        width: 130%;
      }
      b {
        display: block;
        margin-top: -32px;
        text-indent: 34px;
        letter-spacing: 2px;
        line-height: 1;
        font-size: var(--font-size-h4);
        color: var(--color-accent-lt);
      }
    }
  }
  &-action-modal {
    --appearHor-start: 3vw;
    position: absolute;
    right: calc(var(--layout-scrollbar-size, 0) + 8px);
    top: calc(100% + 2.5rem);
    z-index: 10;
    opacity: 0.01;
    transform: translate3d(0, var(--appearVert-start), 0);
    animation: appearHor 250ms ease-in 500ms;
    animation-fill-mode: forwards;
  }
  &-content {
    flex: 1;
  }
  &-footer {
    padding: var(--layout-gap-vertical) 0;
    margin-top: var(--layout-gap-vertical);
    border-top: 1px solid var(--color-border);
  }
  &-notification {
    --appearVert-start: 1rem;
    position: fixed;
    right: 8px;
    bottom: 8px;
    width: var(--notification-size);
    cursor: pointer;
    animation: appearVert var(--transition-duration);
    animation-fill-mode: forwards;
    box-shadow: var(--shadow-depth-4);
    white-space: break-spaces;
    span {
      display: block;
      word-break: break-all;
    }
  }
  &-copy-action {
    --appearVert-start: 1rem;
    animation: appearVert var(--transition-duration);
    animation-fill-mode: forwards;
    position: fixed;
    bottom: 1rem;
    right: 1rem;
  }
  &-notification + &-copy-action {
    right: calc(var(--notification-size) + 1.5rem);
  }
}

@media screen and (max-width: 1280px) {
  .Application {
    &-header {
      grid-template-columns: 180px 1fr 3rem;
    }
  }
}
